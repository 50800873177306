import React, { Fragment, Suspense } from 'react';
import { Navigate, RouterProvider, createBrowserRouter, Outlet } from 'react-router-dom';
import { useUserContext } from '@spacefill/shared/src/utils/UserContext';
import { useFeatureTogglingContext } from '@spacefill/shared/src/utils/FeatureToggling';
import { createRoutesFromObject } from '@spacefill/shared/src/utils/RouterUtils';

import { getCarrierRouter } from './routes/Carriers';
import { getLogisticManagementRouter } from './routes/LogisticManagement';
import { getCustomerRouter } from './routes/Customer';
import { getLogisticProviderRouter } from './routes/LogisticProviders';
import { getWarehouseRouter } from './routes/Warehouses';
import { getImportsRouter } from './routes/Imports';
import { getEdiRouter } from './routes/Edi';
import { getIntegrationsRouter } from './routes/Integrations';
import { getEmailToOrderRouter } from './routes/EmailToOrder';
const LogoutPage = async () =>
    import('@spacefill/shared/src/pages/Logout').then((module) => ({
        Component: module.Logout,
    }));
const EnvView = async () =>
    import('@spacefill/shared/src/pages/Env').then((module) => ({
        Component: module.EnvView,
    }));
const SignInPage = async () =>
    import('@spacefill/shared/src/pages/SignIn').then((module) => ({
        Component: module.SignInPage,
    }));

const AdminConsoleLoggedUserLayout = async () =>
    await import('../layout/AppLayout').then((module) => ({
        Component: module.AdminConsoleLoggedUserLayout,
    }));
const ProfilePage = async () =>
    await import('../pages/ProfilePage').then((module) => ({
        Component: module.ProfilePage,
    }));
const UsersList = async () =>
    import('../pages/users/List').then((module) => ({
        Component: module.UsersList,
    }));
const AdminConsoleThemeProvider = async () =>
    import('../layout/AppLayout').then((module) => ({
        Component: module.AdminConsoleAppLayout,
    }));

const getLoggedUserRoutes = (user, features) => {
    return [
        // Index
        {
            path: '/',
            element: <Navigate to='/logistic-management/orders/' />,
        },
        // Logout
        {
            path: '/logout/',
            lazy: LogoutPage,
        },
        // Profil
        {
            path: '/profile/',
            lazy: ProfilePage,
            redirectFrom: '/profile',
        },
        // Users
        {
            path: '/users/',
            lazy: UsersList,
            redirectFrom: '/users',
        },
        // Logistics providers
        ...getLogisticProviderRouter(user, features),

        // Warehouses
        ...getWarehouseRouter(user, features),

        // Customers
        ...getCustomerRouter(user, features),

        // Logistic management
        ...getLogisticManagementRouter(user, features),

        // Imports
        ...getImportsRouter(user, features),
        ...getEdiRouter(user, features),

        // Integrations
        ...getIntegrationsRouter(user, features),
        // Transport
        ...getCarrierRouter(user, features),

        // email-to-order
        ...getEmailToOrderRouter(user, features),
        // Default
        {
            path: '*',
            element: <Navigate to='/logistic-management/orders/' />,
        },
    ];
};

const DefaultNavigate = () => {
    const locationPathName = window?.location?.pathname;
    return (
        <Navigate
            to={
                locationPathName && locationPathName !== '/' && !locationPathName.includes('logout')
                    ? `/sign-in/?next-url=${encodeURIComponent(locationPathName)}`
                    : '/sign-in/'
            }
        />
    );
};

const unknownUserRoutes = [
    {
        path: '/sign-in/',
        lazy: SignInPage,
    },
    // Version
    {
        path: '/environment/',
        lazy: EnvView,
    },
    {
        path: '*',
        element: <DefaultNavigate />,
    },
];

const RouterContext = () => {
    const { user } = useUserContext();
    const { features } = useFeatureTogglingContext();

    let routes;

    if (user?.logged) {
        routes = getLoggedUserRoutes(user, features);
    } else {
        routes = unknownUserRoutes;
    }

    const formattedRoutes = createRoutesFromObject(routes);

    let router;
    if (user?.logged) {
        router = createBrowserRouter(
            [
                {
                    lazy: AdminConsoleLoggedUserLayout,
                    children: [
                        // Version
                        {
                            path: '/environment/',
                            lazy: EnvView,
                        },
                        {
                            path: '/environment',
                            element: <Navigate to='/environment/' />,
                        },
                        {
                            lazy: AdminConsoleThemeProvider,
                            children: formattedRoutes,
                        },
                    ],
                },
            ],
            {
                basename: import.meta.env?.VITE_ADMIN_CONSOLE_ROUTER_BASE_NAME,
            }
        );
    } else {
        router = createBrowserRouter(
            [
                {
                    element: (
                        <Suspense fallback={<Fragment />}>
                            <Outlet />
                        </Suspense>
                    ),
                    children: formattedRoutes,
                },
            ],
            {
                basename: import.meta.env?.VITE_ADMIN_CONSOLE_ROUTER_BASE_NAME,
            }
        );
    }

    return <RouterProvider router={router} />;
};

export { RouterContext };
