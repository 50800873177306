import Cookies from 'universal-cookie';

import { getEnvironmentVariable } from './GetEnvVar';

const cookies = new Cookies();
const envPrefix = getEnvironmentVariable('envPrefix');
const backendUrl = getEnvironmentVariable('backendUrl');
if (envPrefix === 'EXTERNAL-CONSOLE' && !backendUrl) {
    console.error('Backend URL not found');
}

export const isDemo = (backendUrl || '').includes('demo');
export const isSandBox = (backendUrl || '').includes('sandbox');

class ErrorWithDetails extends Error {
    constructor({ message, details, lastResponse }) {
        super(message);
        this.details = details;
        /**
         * super(message) transform an array of string into a string,
         * lastResponse allows to keep and re-use the last response without transformation.
         */
        this.lastResponse = lastResponse;
    }
}
/**
 * @param {String} route
 * @param {Object} params
 * @param {"GET" | "POST" | "PUT" | "PATCH" | "DELETE"} method
 * @param {HeadersInit} headers
 * @returns {Promise<Response> | null}
 */
export async function call(route, params = {}, method = 'GET', headers = {}, signal = undefined) {
    const response = await fetchBackend(route, params, method, headers, signal);
    if (!response) {
        return;
    }

    if (!response.ok) {
        let errorDetails = {};
        try {
            const responseAsJson = await response.json();
            errorDetails = {
                message: responseAsJson.error || responseAsJson.message || 'Unknown error from server.',
                details: responseAsJson.details || responseAsJson,
            };
        } catch (e) {
            const responseText = await response.text();
            errorDetails = {
                message: `Unexpected error with status ${response.status}: ${e}`,
                details: responseText,
            };
        }
        throw new ErrorWithDetails(errorDetails);
    }
    if (response.status === 204) {
        return Promise.resolve({});
    }

    try {
        return await response.json();
    } catch (error) {
        console.error('Parsing JSON error on route: ', route, error);
        return {};
    }
}

/**
 * @param {String} route
 * @param {Object} params
 * @param {"GET" | "POST" | "PUT" | "PATCH" | "DELETE"} method
 * @param {HeadersInit} headers
 * @returns {Promise<Response> | null}
 */
export async function download(route, params, method = 'GET', headers = {}) {
    const response = await fetchBackend(route, params, method, headers);
    if (!response) {
        return;
    }

    if (!response.ok) {
        const responseAsJson = await response.json();
        throw new Error(responseAsJson.message);
    }

    return response.blob();
}

/**
 * @param {String} route
 * @param {Object} params
 * @param {"GET" | "POST" | "PUT" | "PATCH" | "DELETE"} method
 * @param {HeadersInit} additionalHeaders
 * @returns {Promise<Response> | null}
 */
async function fetchBackend(route, params = {}, method, additionalHeaders, signal = undefined) {
    if (!backendUrl) {
        return null;
    }

    const isUsingBody = ['POST', 'PUT', 'PATCH'].includes(method);
    let body = new FormData(); // FormData or JSON if no files will be provided

    const jwtToken = cookies.get(getJwtTokenName());
    const isImpersonated = !!cookies.get('jwtTokenExternalConsoleImpersonator');
    const headers = {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
        ...(isImpersonated && { 'Spacefill-Ctx-Impersonated': '1' }),
        ...additionalHeaders,
    };

    const url = new URL(backendUrl);
    url.pathname = route;
    let requestContainsFiles = false;
    if (isUsingBody) {
        // Extract files from params to add them in FormData
        for (const [key, value] of Object.entries(params)) {
            if (Array.isArray(value) && value.length > 0 && value.some((item) => item instanceof File)) {
                for (const file of value) {
                    body.append(key, file);
                }
                delete params[key];
                requestContainsFiles = true;
            } else if (value instanceof File) {
                body.append(key, value);
                delete params[key];
                requestContainsFiles = true;
                break;
            }
        }

        if (requestContainsFiles) {
            body.append('body', JSON.stringify(params));
            // Do not send multipart/form-data to let the browser set the correct content-type
            delete headers['Content-Type'];
        } else {
            body = JSON.stringify(params);
        }
    } else {
        for (const [key, value] of Object.entries(params)) {
            url.searchParams.append(key, value);
        }
    }

    return fetch(url, {
        headers,
        method,
        mode: 'cors',
        ...(isUsingBody ? { body } : {}),
        signal,
    });
}

function getJwtTokenName() {
    switch (envPrefix) {
        case 'ADMIN-CONSOLE':
            return 'jwtTokenAdminConsole';
        case 'EXTERNAL-CONSOLE':
            return 'jwtTokenExternalConsole';
        default:
            break;
    }
}

export function granularityFieldsToQueryParam(object, prefix = '') {
    const query = {};
    for (const [key, option] of Object.entries(object)) {
        if (option?.value) {
            query[`${prefix}[${key}]`] = option.value;
        }
    }
    return query;
}
