import React from 'react';
import { Navigate } from 'react-router-dom';

const NewComponent = async () =>
    import('../../pages/integrations/Connectors/New').then((module) => ({
        Component: module.New,
    }));

const IntegrationsIndex = async () =>
    import('../../pages/integrations/Index').then((module) => ({
        Component: module.IntegrationsIndex,
    }));

const ViewComponent = async () =>
    import('../../pages/integrations/Connectors/View').then((module) => ({
        Component: module.View,
    }));

const getIntegrationsRouter = (_user, _features) => [
    {
        path: '/integrations/',
        lazy: IntegrationsIndex,
        element: <Navigate to='/integrations/jobs/' />,
    },
    {
        path: '/integrations/instances',
        lazy: IntegrationsIndex,
    },
    {
        path: '/integrations/jobs',
        lazy: IntegrationsIndex,
    },
    {
        path: '/integrations/connectors',
        lazy: IntegrationsIndex,
    },
    {
        path: '/integrations/connectors/new',
        lazy: NewComponent,
    },
    {
        path: '/integrations/connectors/:id',
        lazy: ViewComponent,
    },
    {
        path: '/integrations/instances/new',
        lazy: async () =>
            import('../../pages/integrations/Instances/NewForm').then((module) => ({
                Component: module.NewInstance,
            })),
    },
    {
        path: '/integrations/instances/:id',
        lazy: async () =>
            import('../../pages/integrations/Instances/Edit').then((module) => ({
                Component: module.EditInstance,
            })),
    },
];

export { getIntegrationsRouter };
