import { useState, useEffect, useCallback } from 'react';
import * as Backend from '@spacefill/shared/src/utils/Backend';
import { useTranslation } from 'react-i18next';
import constate from 'constate';
import jwtDecode from 'jwt-decode';
import { omit, sortBy } from 'lodash';
import Cookies from 'universal-cookie';
import { toast } from '@spacefill/uikit/src/components/Toast/createToast';
import { datadogRum } from '@datadog/browser-rum';
import { useQueryClient } from '@tanstack/react-query';

import { getWarehouseLabel } from '../components/warehouses/configuration/Helpers';

import { getEnvironmentVariable } from './GetEnvVar';

const cookies = new Cookies();

function useUser() {
    const { t, i18n } = useTranslation();
    const envPrefix = getEnvironmentVariable('envPrefix');
    const queryClient = useQueryClient();

    let jwtToken;
    let jwtTokenImpersonator;

    switch (envPrefix) {
        case 'ADMIN-CONSOLE':
            jwtToken = cookies.get('jwtTokenAdminConsole');
            break;
        case 'EXTERNAL-CONSOLE':
            jwtToken = cookies.get('jwtTokenExternalConsole');
            jwtTokenImpersonator = cookies.get('jwtTokenExternalConsoleImpersonator');
            break;
        default:
            break;
    }
    let decodedJwt;
    let decodedJwtImpersonator;
    try {
        if (jwtToken) {
            decodedJwt = jwtDecode(jwtToken);
        }
        if (jwtTokenImpersonator) {
            decodedJwtImpersonator = jwtDecode(jwtTokenImpersonator);
        }
    } catch (error) {
        console.error(error);
    }

    const [user, setUser] = useState(
        jwtToken && decodedJwt
            ? {
                  logged: true,
                  jwtToken,
                  id: decodedJwt?.user_id,
                  impersonator:
                      jwtTokenImpersonator && decodedJwtImpersonator
                          ? {
                                jwtToken: jwtTokenImpersonator,
                                id: decodedJwtImpersonator?.user_id,
                            }
                          : undefined,
              }
            : {
                  logged: false,
                  jwtToken: undefined,
                  id: undefined,
              }
    );

    const login = async (email, password, impersonateUserId, rememberMe, mfaSkipped = null, mfaCode = null) => {
        const backendUrl = getEnvironmentVariable('backendUrl');

        const currentDate = new Date();
        const data = {
            email,
            password,
            mfaCode,
            mfaSkipped,
            applicationName: envPrefix.replace('-', '_'),
        };

        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        };

        const response = await fetch(backendUrl + '/auth/login', options);
        if (!response.ok) {
            throw await response.json();
        }
        const resultAuthenticate = await response.json();
        const tok = resultAuthenticate.access_token;

        if (jwtDecode(tok).user_id === '00000000-0000-0000-0000-000000000000') {
            setUser({
                logged: false,
                jwtToken: tok,
                id: undefined,
            });
            throw new Error(jwtDecode(tok).role);
        }

        let user = {
            logged: true,
            jwtToken: tok,
            id: jwtDecode(tok).user_id,
        };
        switch (envPrefix) {
            case 'ADMIN-CONSOLE':
                cookies.set('jwtTokenAdminConsole', tok, {
                    domain: document.domain,
                    path: '/',
                    expires: rememberMe
                        ? new Date(currentDate.getFullYear() + 10, currentDate.getMonth(), currentDate.getDay())
                        : undefined,
                });
                break;
            case 'EXTERNAL-CONSOLE':
                cookies.set('jwtTokenExternalConsole', tok, {
                    domain: document.domain,
                    path: '/',
                    expires: rememberMe
                        ? new Date(currentDate.getFullYear() + 10, currentDate.getMonth(), currentDate.getDay())
                        : undefined,
                });
                break;
            default:
                break;
        }
        cookies.set('jwtTokenRememberMe', !!rememberMe, {
            domain: document.domain,
            path: '/',
            expires: undefined,
        });

        if (impersonateUserId) {
            const userImpersonated = await Backend.call(
                'auth/impersonate',
                { impersonate_user_id: impersonateUserId },
                'POST'
            );
            const tok2 = userImpersonated.access_token;
            switch (envPrefix) {
                case 'ADMIN-CONSOLE':
                    cookies.set('jwtTokenAdminConsoleImpersonator', tok, {
                        domain: document.domain,
                        path: '/',
                        expires: rememberMe
                            ? new Date(currentDate.getFullYear() + 10, currentDate.getMonth(), currentDate.getDay())
                            : undefined,
                    });
                    cookies.set('jwtTokenAdminConsole', tok2, {
                        domain: document.domain,
                        path: '/',
                        expires: rememberMe
                            ? new Date(currentDate.getFullYear() + 10, currentDate.getMonth(), currentDate.getDay())
                            : undefined,
                    });
                    break;
                case 'EXTERNAL-CONSOLE':
                    cookies.set('jwtTokenExternalConsoleImpersonator', tok, {
                        domain: document.domain,
                        path: '/',
                        expires: rememberMe
                            ? new Date(currentDate.getFullYear() + 10, currentDate.getMonth(), currentDate.getDay())
                            : undefined,
                    });
                    cookies.set('jwtTokenExternalConsole', tok2, {
                        domain: document.domain,
                        path: '/',
                        expires: rememberMe
                            ? new Date(currentDate.getFullYear() + 10, currentDate.getMonth(), currentDate.getDay())
                            : undefined,
                    });
                    break;
                default:
                    break;
            }

            user = {
                logged: true,
                jwtToken: tok2,
                id: jwtDecode(tok2).user_id,
                impersonator: {
                    jwtToken: tok,
                    id: jwtDecode(tok).user_id,
                },
            };
        }

        setUser(user);
    };

    const logout = useCallback(() => {
        switch (envPrefix) {
            case 'ADMIN-CONSOLE':
                cookies.remove('jwtTokenAdminConsole', {
                    domain: document.domain,
                    path: '/',
                });
                break;
            case 'EXTERNAL-CONSOLE':
                cookies.remove('jwtTokenExternalConsole', {
                    domain: document.domain,
                    path: '/',
                });
                cookies.remove('jwtTokenExternalConsoleImpersonator', {
                    domain: document.domain,
                    path: '/',
                });
                break;
            default:
                break;
        }
        cookies.remove('jwtTokenRememberMe', {
            domain: document.domain,
            path: '/',
        });
        setUser({
            logged: false,
            jwtToken: undefined,
            id: undefined,
        });
    }, [envPrefix]);

    const switchRole = async (role) => {
        const currentDate = new Date();
        const resultAuthenticate = await Backend.call(
            'auth/reauthenticate-with-role',
            {
                role,
            },
            'POST'
        );

        const jwtToken = resultAuthenticate?.access_token;
        if (jwtDecode(jwtToken).user_id === '00000000-0000-0000-0000-000000000000') {
            setUser({
                logged: false,
                jwtToken,
                id: undefined,
            });
            throw new Error(jwtDecode(jwtToken).role);
        }

        const rememberMe = cookies.get('jwtTokenRememberMe');
        cookies.set('jwtTokenExternalConsole', jwtToken, {
            domain: document.domain,
            path: '/',
            expires: rememberMe
                ? new Date(currentDate.getFullYear() + 10, currentDate.getMonth(), currentDate.getDay())
                : undefined,
        });

        // Invalidate all queries because user has changed
        await queryClient.invalidateQueries();
        getUser();
    };

    const getUser = useCallback(
        async function getUser() {
            return Backend.call('users')
                .then(async (result) => {
                    if (!result || !result.user) {
                        toast.error(t("This user doesn't exist or have been removed"));
                        logout();
                        return;
                    }
                    datadogRum.setUser({
                        id: result.user.id,
                        email: result.user.email,
                        impersonate_user: document.cookie.includes('jwtTokenExternalConsoleImpersonator'),
                        user_role: result.user.role,
                    });
                    if (envPrefix === 'ADMIN-CONSOLE' && result.user?.isAdmin) result.user.role = 'ADMIN';
                    let newUser = Object.assign({}, user);
                    let { customer, logisticProvider } = Object.assign({}, result.user);

                    if (!customer && result.user?.role === 'SHIPPER') {
                        if (!(newUser?.customer?.isLoaded ?? false)) {
                            customer = { isLoaded: true };
                            toast.error(t('Expired session. Please refresh this page'));
                            console.error('Customer not retrieved within UserContext');
                        } else {
                            customer = { isLoaded: false };
                        }
                    } else {
                        customer = {
                            ...customer,
                            isLoaded: true,
                        };
                    }
                    if (!logisticProvider && result.user?.role === 'PROVIDER') {
                        if (!(newUser?.logisticProvider?.isLoaded ?? false)) {
                            logisticProvider = { isLoaded: true };
                            toast.error(t('Expired session. Please refresh this page'));
                            console.error('Logistic provider not retrieved within UserContext');
                        } else {
                            logisticProvider = { isLoaded: false };
                        }
                    } else {
                        logisticProvider = {
                            ...logisticProvider,
                            isLoaded: true,
                        };
                    }

                    if (result.user?.preferredLanguage ?? false) {
                        const queryParams = new URLSearchParams(window.location.search);
                        if (queryParams.get('lang') === null) {
                            await i18n.changeLanguage(result.user?.preferredLanguage);
                        }
                        localStorage.setItem('spacefillLocale', result.user?.preferredLanguage);
                        newUser.preferredLanguage = result.user?.preferredLanguage;
                    }

                    const relationships = result.user?.customerWarehouseConfigurations.reduce(
                        (acc, cwc) => {
                            // Collect unique customer
                            if (!acc.customerIdsSet.has(cwc.customer.id)) {
                                acc.customerIdsSet.add(cwc.customer.id);
                                acc.customers.push(cwc.customer);
                            }

                            // Collect unique warehouse
                            if (!acc.warehouseIdsSet.has(cwc.warehouse.id)) {
                                acc.warehouseIdsSet.add(cwc.warehouse.id);
                                acc.warehouses.push({
                                    ...cwc.warehouse,
                                    label: getWarehouseLabel(result.user, cwc.warehouse),
                                });
                            }

                            // Collect unique LSP
                            if (!acc.logisticProviderIdsSet.has(cwc.warehouse.logisticProvider.id)) {
                                acc.logisticProviderIdsSet.add(cwc.warehouse.logisticProvider.id);
                                acc.logisticProviders.push(cwc.warehouse.logisticProvider);
                            }

                            acc.customerWarehouseConfigurations.push({
                                ...cwc,
                                warehouse: {
                                    ...cwc.warehouse,
                                    label: getWarehouseLabel(result.user, cwc.warehouse),
                                },
                            });

                            return acc;
                        },
                        {
                            customerWarehouseConfigurations: [],
                            customerIdsSet: new Set(),
                            warehouseIdsSet: new Set(),
                            logisticProviderIdsSet: new Set(),
                            customers: [],
                            warehouses: [],
                            logisticProviders: [],
                        }
                    );

                    newUser = {
                        ...newUser,
                        ...omit(result.user ?? {}, ['customer', 'logisticProvider', 'userPermissions']),
                        customer,
                        logisticProvider,
                        customerWarehouseConfigurations: relationships.customerWarehouseConfigurations,
                        customerIds: Array.from(relationships.customerIdsSet),
                        warehouseIds: Array.from(relationships.warehouseIdsSet),
                        customers: sortBy(relationships.customers, 'company'),
                        warehouses: sortBy(relationships.warehouses, 'label'),
                        logisticProviders: sortBy(relationships.logisticProviders, 'company'),
                        features: {
                            hasAnalyticsEnabled:
                                (customer?.hasAnalyticsEnabled || logisticProvider?.hasAnalyticsEnabled) ?? false,
                            orderManagementEnabled: customer?.orderManagementEnabled ?? false,
                            customDashboard: customer?.customDashboard ?? logisticProvider?.customDashboard ?? null,
                            importOrdersUrl: customer?.importOrdersUrl ?? logisticProvider?.importOrdersUrl ?? null,
                            orderRoutingEnabled: customer?.orderRoutingEnabled ?? false,
                            canUseIncidents:
                                result.user.customer?.incidentsEnabled ||
                                result.user.logisticProvider?.incidentsEnabled ||
                                (result.user?.customerWarehouseConfigurations ?? []).some(
                                    (x) =>
                                        x.warehouse?.logisticProvider.incidentsEnabled || x.customer?.incidentsEnabled
                                ),
                        },
                        toggleFeatures: result.toggleFeatures,
                    };
                    setUser(newUser);
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(t('An error occurred while retrieving user data, or server is not available'));
                });
        },
        [i18n, logout, t, user, envPrefix]
    );

    useEffect(() => {
        // Token is expired
        if ((decodedJwt?.exp ?? false) && new Date(decodedJwt?.exp * 1000) < Date.now()) {
            toast.error(t('Your session has expired, please sign in again'));
            logout();
            return;
        }

        if (user.id && !(user?.customer?.isLoaded || user?.logisticProvider?.isLoaded)) {
            getUser();
        }
    }, [getUser, user, envPrefix, decodedJwt?.exp, logout, t]);
    return { user, setUser, login, logout, switchRole, getUser };
}

const [UserProvider, useUserContext] = constate(useUser);

export { UserProvider, useUserContext };
