import { init18n } from '@spacefill/shared/src/i18n/I18n';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ApolloProvider } from '@apollo/client';
import { client as ApolloClient } from '@spacefill/shared/src/utils/Apollo';
import { FeatureTogglingProvider, FeatureTogglingToolbar } from '@spacefill/shared/src/utils/FeatureToggling';
import { ThemeStateProvider } from '@spacefill/shared/src/utils/ThemeStateContext';
import { TopBannerContextProvider } from '@spacefill/shared/src/utils/TopBannerContext';
import { UserProvider } from '@spacefill/shared/src/utils/UserContext';
import { Toaster } from 'react-hot-toast';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';

import { RouterContext } from './router/RouterContext';
import { featureSettings } from './Features';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
        },
    },
});

init18n();

const App = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <ApolloProvider client={ApolloClient}>
                <FeatureTogglingProvider featureSettings={featureSettings}>
                    <ThemeStateProvider>
                        <TopBannerContextProvider>
                            <UserProvider>
                                <FeatureTogglingToolbar />
                                <RouterContext />
                                <Toaster />
                            </UserProvider>
                        </TopBannerContextProvider>
                    </ThemeStateProvider>
                </FeatureTogglingProvider>
            </ApolloProvider>
            {import.meta.env?.VITE_EXTERNAL_CONSOLE_REACT_QUERY_DEVTOOLS_ENABLED && (
                <ReactQueryDevtools initialIsOpen />
            )}
        </QueryClientProvider>
    );
};

export { App };
